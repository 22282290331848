// Module
var code = `<template>
  <require from="./verify_mail.scss"></require>
  <require from="../../../components/animated_checkmark/animated_checkmark"></require>
  <section id="verify_mail" class="flex-column">
    <div id="header" class="flex-row">
      <i class="fas fa-arrow-left" click.delegate="rouver.natigateBack()"></i>
      <h1 i18n="auth.verify_mail1"></h1>
    </div>
    <div class="flex-grow"></div>
    <animated-checkmark></animated-checkmark>
    <p i18n="auth.verify_mail2"></p>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" click.delegate="goToHome()" i18n="home.goBackHome"></button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;