import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { SingletonService } from "singleton";
import { default as env } from '../../../../config/environment.json'
import { AuthService } from 'aurelia-auth';
import { User } from 'components/models/UserModel';

@autoinject
export class Verify {
  private me: User;
  private code: string;
  isSuccess: boolean = false;

  constructor(private router: Router, private singleton: SingletonService, private authServiceInstance: AuthService) {}

  async activate({ code }) {
    this.code = code;
    await this.verify(code);
  }

  goToHome() {
    this.router.navigateToRoute("home");
  }

  async verify(code) {
    try {
      const response = await fetch(`${env.users_api}/auth/verify`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code }),
      });
      const data = await response.json();
      console.log(data);
      this.singleton.setMe(data.user);
      let token = this.authServiceInstance.getTokenPayload()
      token = this.authServiceInstance.setToken(token)
      this.isSuccess = response.ok && response.status == 200;
    } catch (error) {
      console.error('Verification failed:', error);
      this.isSuccess = false;
    }
  }
}

