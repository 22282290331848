import { Router } from "aurelia-router";
import { AuthService } from "aurelia-auth";
import { autoinject } from "aurelia-dependency-injection";
import { noView, processContent } from "aurelia-framework";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { UsersHttpClients } from "http_clients/UsersHttpClients";

@noView()
@processContent(false)
@autoinject
export class intermediate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private usersHttpClients: UsersHttpClients
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async activate(params: any) {
    console.log(params);
    // Vérifie si les paramètres contiennent le token d'authentification de Google
    const socialCode = params.code;
    const socialState = params.code;
    await this.backendUserSocialTwitter(socialCode, socialState);
  }

  async backendUserSocialTwitter(socialCode, socialState) {
    let request = await this.usersHttpClients.fetch("/auth/x", {
      method: "POST",
      body: JSON.stringify({
        code: socialCode,
        state: socialState,
      }),
    });
    let is200ok = await checkResponseStatus(request);
    let reponse = await is200ok.json();
    await this.initialiseToken(reponse.token);
  }

  async initialiseToken(token) {
    // Vérifie si les paramètres contiennent le token d'authentification de Google
    if (token) {
      // Stocke le token dans la session ou autre endroit approprié
      this.authService.setToken(token);

      // Redirige l'utilisateur vers la page souhaitée après connexion
      this.router.navigateToRoute("pin_code/create"); // Remplace 'dashboard' par ta page cible
    } else {
      // Gère l'erreur ou la redirection en cas d'échec de l'authentification
      this.router.navigateToRoute("signin"); // Redirige l'utilisateur vers la page de connexion
    }
  }
}
