// Module
var code = `<template>
    <require from="./verify.scss"></require>
    <require from="../../../components/animated_checkmark/animated_checkmark"></require>
    <section id="verify" class="flex-column">
        <div class="flex-grow"></div>
        <animated-checkmark if.bind="isSuccess"></animated-checkmark>
        <h3 i18n="auth.mail_failure1" if.bind="!isSuccess">Il y a eu une erreur lors de la validation de votre adresse mail.</h3>
        <h2 i18n="auth.mail_success1" if.bind="isSuccess">Votre adresse e-mail est validée.</h2>
        <div class="flex-grow"></div>
        <button class="btn btn-primary" click.delegate="goToHome()" i18n="goBackHome">Retourner à l'accueil</button>
        <div class="spacer-default"></div>
    </section>
</template>
`;
// Exports
export default code;