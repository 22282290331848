// Module
var code = `<template>
  <require from="./reset_password.scss"></require>
  <require from="../../../components/animated_checkmark/animated_checkmark"></require>
  <section id="reset_password" class="flex-column">
    <div id="header" class="flex-row">
      <h1 t="auth.reset_password.title"></h1>
    </div>
    <form id="new_password" class="inputs" submit.delegate="confirmedPassword()">
      <div>
        <input id="reset_password" type="password" value.bind="reset_password"
          i18n="[placeholder]auth.reset_password.new_password">
      </div>
      <div>
        <input id="reset_password_verification" type="password" value.bind="reset_password_verification"
          i18n="[placeholder]auth.reset_password.confirm_new_password">
      </div>
    </form>
    <div class="flex-grow"></div>
    <animated-checkmark if.bind="success == true"></animated-checkmark>
    <div class="error">
      <span>
        \${errorMessage}
      </span>
    </div>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" click.delegate="changePassword()" form="new_password"
      disabled.bind="!reset_password || !reset_password_verification">
      <span i18n="home.send"></span>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;