import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { UsersHttpClients } from "../../../http_clients/UsersHttpClients"
import { checkResponseStatus } from "http_clients/checkResponseStatus"
import { json } from "aurelia-fetch-client"

@autoinject
export class verifyMail {
  constructor(private router: Router,
    private usersHttpClients: UsersHttpClients) {
    this.sendEmail();

  }


  async sendEmail() {
    let httpResult = await this.usersHttpClients.fetch('/auth/resend-verify-email')
    let isHttpOk = await checkResponseStatus(httpResult)
    let response = await isHttpOk.json()
  }

  goToHome() {
    this.router.navigateToRoute('home')
  }
}
