import { autoinject, computedFrom, inject } from "aurelia-framework";
import { AuthService } from "aurelia-auth";
import { Router } from "aurelia-router";
import { UsersHttpClients } from "../../../http_clients/UsersHttpClients";
import { json } from "aurelia-fetch-client";

@autoinject
export class ResetPassword {
  private reset_password: String;
  private reset_password_verification;
  private verification_code: String;
  private router: Router;
  private success: boolean = false;

  constructor(
    private authService: AuthService,
    router: Router,
    private usersHttpClients: UsersHttpClients
  ) {
    this.router = router;
  }

  activate({ code }) {
    this.verification_code = code;
    console.log(this.verification_code)
  }

  //Verify if sent code and old password correct
  changePassword() {
    if (this.validateNewPassword() == true) {
      var that = this;
      this.usersHttpClients
        .fetch("/auth/reset", {
          method: "POST",
          body: json({
            id: this.verification_code,
            password: this.reset_password,
          }),
        })
        .then((response) => {
          if (response.ok) {
            // response.status == 200
            this.success = true;
            setTimeout(() => {
              that.router.navigateToRoute("login")
            }, 3000);

          } else {
            // response.status == 409
            response
              .json()
              .then((json_response) => {
                if (json_response.errors.msg == "WRONG_PASSWORD") {
                  console.log("wrong password");
                }
                if (
                  json_response.errors.msg[0].msg == "PASSWORD_TOO_SHORT_MIN_5"
                ) {
                  console.log("password too short");
                }
              })
              .catch((err) =>
                console.log("Could not convert response to JSON." + err)
              );
          }
      });
    }
  }

  validateNewPassword() {
    if (this.reset_password == this.reset_password_verification) return true;
    else return false;
  }
}
